export type DrawOptions = {
  lineWidth?: number
  gapToLineRatio?: number
  lineCap?: CanvasPathDrawingStyles['lineCap']
  strokeStyle?: CanvasFillStrokeStyles['strokeStyle']
}
type DrawOptionsFull = DrawOptions & Required<Pick<DrawOptions, 'lineWidth' | 'lineCap' | 'strokeStyle'>>
export type SegmentMetrics = DrawOptionsFull & {
  gapWidth: number
  radius: number
  segmentWidth: number
}

const defaultOptions: DrawOptionsFull = {
  lineWidth: 2,
  gapToLineRatio: 1,
  lineCap: 'round',
  strokeStyle: '#212121',
}

export function useWaveformDrawing(canvas: Ref<HTMLCanvasElement | undefined>, options?: DrawOptions) {
  const optionsFull = { ...defaultOptions, ...options }
  const canvasW = ref(0)
  const context = ref<CanvasRenderingContext2D>()
  const contextClone = ref<CanvasRenderingContext2D>()
  const staticWaveData = ref<number[]>()
  let staticNormalizer = (n: number) => n

  const segmentMetrics = computed<SegmentMetrics>(() => {
    const { lineWidth: lineW, gapToLineRatio = 1, ...rest } = optionsFull
    const dpr = window.devicePixelRatio || 1
    const lineWidth = lineW * dpr
    const gapWidth = Math.ceil(lineWidth * gapToLineRatio)
    return {
      ...rest,
      lineWidth,
      gapWidth,
      segmentWidth: (lineWidth + gapWidth) * 2,
      radius: (lineWidth + gapWidth) / 2,
    }
  })

  const numSegments = computed(() => {
    const { radius, segmentWidth } = segmentMetrics.value
    const widthWithoutPaddings = canvasW.value - radius * 2
    return Math.round(widthWithoutPaddings / segmentWidth)
  })

  let resizeTimeout: number
  const { stop: stopResizeObserver } = useResizeObserver(canvas, () => {
    window.clearTimeout(resizeTimeout)
    resizeTimeout = window.setTimeout(() => {
      if (!canvas.value) {
        return
      }
      setCanvasDensity(canvas.value)
      canvasW.value = canvas.value.width
      if (staticWaveData.value) {
        draw(staticWaveData.value, staticNormalizer)
      }
    }, 0)
  })

  watch(canvas, (canvas, oldCanvas) => {
    if (oldCanvas && !canvas) {
      context.value = undefined
      contextClone.value = undefined
      stopResizeObserver()
      return
    }
    if (!canvas) {
      return
    }
    setCanvasDensity(canvas)
    canvasW.value = canvas.width
    context.value = canvas.getContext('2d', { willReadFrequently: true }) ?? undefined
    if (staticWaveData.value) {
      draw(staticWaveData.value, staticNormalizer)
    }
  }, { immediate: true })

  function drawStaticWave(waveformData: Ref<number[]>, normalizer: (_n: number) => number) {
    watch(waveformData, (waveformData) => {
      staticWaveData.value = waveformData
      staticNormalizer = normalizer
      if (waveformData) {
        draw(staticWaveData.value, normalizer)
      }
    }, { immediate: true })
  }

  function draw(waveformData: number[] | Uint8Array, normalizer: (_n: number) => number) {
    const ctx = context.value
    if (!ctx) {
      return
    }
    const { lineWidth, lineCap, strokeStyle, segmentWidth, radius } = segmentMetrics.value
    const maxHeight = ctx.canvas.height - radius * 2 - lineWidth
    const segments = numSegments.value
    const steps = calculateSteps(waveformData.length, segments)
    ctx.lineWidth = lineWidth
    ctx.lineCap = lineCap
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    /*
    segment: line-up, top-arc, line-down, bottom-arc
      ⌢
     | |
       ⌣
    */
    ctx.beginPath()
    drawPaddingArc(ctx, 0, segmentMetrics.value, true)
    let x = radius
    for (let s = 0; s < steps.length; s++) {
      const { stepSize, length } = steps[s]
      const startIndex = steps[s - 1]?.length ?? 0
      const lastIndex = startIndex + length - 1
      let indexInStep = 0
      let max = 0
      for (let i = startIndex; i <= lastIndex; i++) {
        max = Math.max(waveformData[i], max)
        if (indexInStep % stepSize === stepSize - 1 || i === lastIndex) {
          const height = normalizer(max) * maxHeight
          drawLineSegment(ctx, x, height, segmentMetrics.value)
          x += segmentWidth
          max = 0
        }
        indexInStep++
      }
    }
    drawPaddingArc(ctx, x + radius, segmentMetrics.value, false)
    ctx.strokeStyle = strokeStyle
    ctx.stroke()
    if (contextClone.value) {
      cloneDrawing(ctx, contextClone.value)
    }
  }

  function cloneDrawingToCanvas(targetCanvas: Ref<HTMLCanvasElement | undefined>) {
    watch(targetCanvas, (targetCanvas) => {
      cloneSize()
      contextClone.value = targetCanvas?.getContext('2d') ?? undefined
      if (contextClone.value && context.value) {
        cloneDrawing(context.value, contextClone.value)
      }
    }, { immediate: true })

    function cloneSize() {
      if (targetCanvas.value && canvas.value) {
        targetCanvas.value.width = canvas.value.width
        targetCanvas.value.height = canvas.value.height
      }
    }
  }

  return {
    draw,
    drawStaticWave,
    cloneDrawingToCanvas,
  }
}

function cloneDrawing(fromCtx: CanvasRenderingContext2D, toCtx: CanvasRenderingContext2D) {
  const fromCanvas = fromCtx.canvas
  if (fromCanvas.width && fromCanvas.height) {
    const imageData = fromCtx.getImageData(0, 0, fromCanvas.width, fromCanvas.height)

    const toCanvas = toCtx.canvas
    if (toCanvas.width !== fromCanvas.width || toCanvas.height !== fromCanvas.height) {
      toCanvas.width = fromCanvas.width
      toCanvas.height = fromCanvas.height
    }
    toCtx.putImageData(imageData, 0, 0)
  }
}

export type StepData = { stepSize: number, stepsCount: number, length: number }

function calculateSteps(totalDistance: number, totalSteps: number): [StepData] | [StepData, StepData] {
  if (!totalDistance || !totalSteps) {
    return [{ stepSize: 0, stepsCount: 0, length: 0 }]
  }
  if (totalDistance <= totalSteps) {
    return [{ stepSize: 1, stepsCount: totalDistance, length: totalDistance }]
  }
  const exactStep = totalDistance / totalSteps
  const small = Math.floor(exactStep)
  const big = Math.ceil(exactStep)
  if (big - small === 0) {
    return [{ stepSize: exactStep, stepsCount: totalSteps, length: totalDistance }]
  }
  /**
   * numSmalls + numBigs = totalSteps
   * numSmalls = totalSteps − numBigs
   * small*numSmalls + big*numBigs = totalDistance
   * small*(totalSteps − numBigs) + big*numBigs = totalDistance
   * small*totalSteps - small*numBigs + big*numBigs = totalDistance
   * small*totalSteps + numBigs*(big - small) = totalDistance
   * numBigs = (totalDistance - small * totalSteps) / (big - small)
  */
  const numBigs = (totalDistance - small * totalSteps) / (big - small)
  const numSmalls = totalSteps - numBigs

  return [
    { stepSize: small, stepsCount: numSmalls, length: small * numSmalls },
    { stepSize: big, stepsCount: numBigs, length: big * numBigs },
  ]
}

function setCanvasDensity(canvas: HTMLCanvasElement) {
  const dpr = window.devicePixelRatio || 1
  canvas.width = canvas.offsetWidth * dpr
  canvas.height = canvas.offsetHeight * dpr
}

function drawLineSegment(ctx: CanvasRenderingContext2D, x: number, h: number, segmentMetrics: SegmentMetrics) {
  const { lineWidth, radius } = segmentMetrics
  const bottom = radius + lineWidth / 2
  const y0 = ctx.canvas.height - bottom
  const y = ctx.canvas.height - h - bottom
  ctx.moveTo(x, y0)
  ctx.lineTo(x, y)
  ctx.arc(x + radius, y, radius, Math.PI, 0)
  ctx.lineTo(x + radius * 2, y0)
  ctx.arc(x + radius * 3, y0, radius, Math.PI, 0, true)
}

function drawPaddingArc(ctx: CanvasRenderingContext2D, x: number, segmentMetrics: SegmentMetrics, counterClockwise: boolean) {
  const { lineWidth, radius } = segmentMetrics
  const bottom = radius + lineWidth / 2
  const y = ctx.canvas.height - bottom
  ctx.arc(x, y, radius, Math.PI, 0, counterClockwise)
}
