export default defineNuxtPlugin(() => {
  const { loggedIn } = useAuth()

  // If we are running via wrangler dev, use ws:
  const wss = document.location.protocol === 'http:' ? 'ws' : 'wss'

  const { data, send, open, close } = useWebSocket<string>(`${wss}://individuallist.xyz/wspool/connect`, {
    immediate: false,
    autoReconnect: {
      retries: 3,
      delay: 1000,
      onFailed() {
        console.error('Failed to connect WebSocket after 3 retries')
      },
    },
    onConnected: () => {
      send(JSON.stringify({ type: 'subscribe', channel: 'user:notification' }))
    },
  })

  watch(loggedIn, () => {
    if (loggedIn.value) {
      open()
    }
    else {
      close()
    }
  }, { immediate: true })

  // TODO nuxtApp.$pageLifecycle.addFrozenListener(close)

  return {
    provide: {
      ws: {
        data,
        send,
      },
    },
  }
})
