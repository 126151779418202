import { computedAsync } from '@vueuse/core'
import { htmlToText } from '#backend/utils/content-parse'
import type { mastodon } from '#types'
import { uploadContent } from '~/lib/publish'
import type { UploadContentMedia, WaveformData } from '~/types/content-media'

interface CreateContentUpload {
  statusId?: string
  conversationId?: string
  publishId: string
  inReplyToId?: string
  uploadMedia?: UploadContentMedia[]
  html?: string
  visibility?: mastodon.v1.StatusVisibility
}

interface RecordType {
  upload?: Ref<mastodon.v1.Status | undefined>
  evaluating?: Ref<boolean>
}

interface ProgressInfo {
  sent: number
  total: number
  percentage: number
}

interface DraftParams {
  readonly publishId: string
  readonly content: string
  readonly visibility: mastodon.v1.StatusVisibility
  readonly files?: File[]
  readonly inReplyToId?: string
  readonly editingStatus?: mastodon.v1.Status
}

interface Draft extends DraftParams {
  readonly isEmpty: boolean
  readonly isChanged: boolean
  readonly contentType: '' | 'rich' | 'no-text'
  readonly isUploading: boolean
  readonly errors: unknown[]
}

function removeEmpty(html: string) {
  return html.replace(/<(p|h1|h2|h3|h4|h5|h6)[^>]*>[\s\n\r]*<\/\1>/g, '').trim()
}

function newDraft({ content, editingStatus, ...params }: DraftParams, isUploading: boolean, errors: unknown[]): Draft {
  const html = removeEmpty(content)
  return {
    ...params,
    content: html,
    editingStatus,
    isEmpty: html ? htmlToText(html).trim().replace(/^(@\S+\s?)+/, '').replaceAll(/```/g, '').trim().length === 0 : true,
    isChanged: editingStatus ? removeEmpty(editingStatus.content) !== html : true,
    contentType: html ? /<(p|h1|h2|h3|h4|h5|h6)[^>]*>[^\s<]+<\/\1>/g.test(html) ? 'rich' : 'no-text' : '',
    isUploading,
    errors,
  }
}

export default defineNuxtPlugin(() => {
  const error: Ref<{ conversationId?: string, publishId: string, error: unknown } | undefined> = ref()
  const records = new Map<string, RecordType>()
  const mediaFiles = new Map<string, string[]>()
  const waveforms = reactive(new Map<string, WaveformData>())
  const progress = reactive(new Map<string, ProgressInfo>())
  const drafts = reactive(new Map<string, Draft>())

  function getDraft({ status, inReplyToId }: { status?: Ref<mastodon.v1.Status | null | undefined>, inReplyToId?: Ref<string> }) {
    return computed<Draft>(() => {
      const key = status?.value?.id ?? 'create'
      let draft = drafts.get(key)
      if (!draft) {
        draft = newDraft({
          publishId: status?.value?.id ?? crypto.randomUUID(),
          content: status?.value?.content || '',
          visibility: status?.value?.visibility || 'public',
          inReplyToId: inReplyToId?.value,
          editingStatus: status?.value || undefined,
        }, false, [])
        drafts.set(key, draft)
      }
      return draft
    })
  }

  function updateDraft(draft: Draft, { html, visibility, files, isUploading, errors }: { html?: string, visibility?: mastodon.v1.StatusVisibility, files?: File[], isUploading?: boolean, errors?: unknown[] }) {
    const key = draft.editingStatus?.id ?? 'create'
    drafts.set(key, newDraft({
      ...draft,
      content: html ?? draft.content,
      visibility: visibility ?? draft.visibility,
      files: files ?? draft.files,
    }, isUploading ?? false, errors ?? draft.errors))
  }

  function publishDraft(draft: Draft) {
    const html = draft.content
    if (html) {
      updateDraft(draft, { isUploading: true })
      watch(error, (error) => {
        if (error?.publishId === draft.publishId) {
          const errors = draft.errors
          updateDraft(draft, { errors: [...errors, error] })
        }
      })
      send({
        statusId: draft.editingStatus?.id,
        html,
        uploadMedia: getUploadMediaFromHtml(html, draft.files ?? []),
        publishId: draft.publishId,
        inReplyToId: draft.inReplyToId,
        visibility: draft.visibility,
      })
      const evaluating = records.get(draft.publishId)?.evaluating
      if (evaluating !== undefined) {
        watch(evaluating, (current, last) => {
          if (!current && last && draft.errors.length === 0) {
            const key = draft.editingStatus?.id ?? 'create'
            drafts.delete(key)
          }
        })
      }
    }
  }

  function isUploading(publishId?: string) {
    if (publishId === undefined) {
      return ref(false)
    }
    let evaluating = records.get(publishId)?.evaluating
    if (evaluating === undefined) {
      evaluating = ref(false)
      records.set(publishId, { evaluating })
    }
    return evaluating
  }

  function clean(publishId?: string) {
    if (publishId) {
      records.delete(publishId)
      progress.delete(publishId)
      for (const mediaId of mediaFiles.get(publishId) ?? []) {
        waveforms.delete(mediaId)
        progress.delete(mediaId)
      }
      mediaFiles.delete(publishId)
    }
  }

  function send({ conversationId, publishId, uploadMedia = [], ...params }: CreateContentUpload) {
    if (records.get(publishId)?.upload?.value === undefined) {
      const evaluating = records.get(publishId)?.evaluating ?? ref<boolean>(false)
      records.set(publishId, {
        upload: computedAsync(() => process({ conversationId, publishId, uploadMedia, ...params }), undefined, {
          evaluating,
          onError(err) {
            error.value = { conversationId, publishId, error: err }
          },
        }),
        evaluating,
      })
      mediaFiles.set(publishId, uploadMedia.map(file => file.file.name))
    }
  }

  async function process({ statusId, conversationId, publishId, inReplyToId, html, uploadMedia, visibility }: CreateContentUpload) {
    return uploadContent({ statusId, conversationId, publishId, inReplyToId, html, uploadMedia, visibility }, {
      onVideoPoster: (file) => {
        mediaFiles.set(publishId, [...mediaFiles.get(publishId) ?? [], file.name])
      },
      onWaveformData: (mediaId, data) => {
        waveforms.set(mediaId, data)
      },
      onProgress: (file, payload) => {
        progress.set(file.name, payload)
        const [publishId] = Object.entries(mediaFiles).find(([, names]) => names.includes(file.name)) ?? []
        if (publishId) {
          progress.set(publishId, (mediaFiles.get(publishId) ?? []).reduce<ProgressInfo>((result, name) => {
            const sent = result.sent + (progress.get(name)?.sent ?? 0)
            const total = result.total + (progress.get(name)?.total ?? 0)
            return {
              sent,
              total,
              percentage: Math.round((sent / total) * 100),
            }
          }, { sent: 0, total: 0, percentage: 0 }))
        }
      },
    })
  }

  return {
    provide: {
      mediaUpload: {
        isUploading,
        error,
        send,
        clean,
        waveforms,
        progress,
        getDraft,
        updateDraft,
        publishDraft,
      },
    },
  }
})
