import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs, { extend } from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/ru'
import 'dayjs/locale/de'

export default defineNuxtPlugin(() => {
  extend(relativeTime)
  extend(localizedFormat)
  return {
    provide: {
      dayjs,
    },
  }
})
