import axios from 'axios'

// https://developers.cloudflare.com/workers/platform/limits#request-limits
const PART_SIZE = 99 * 1024 ** 2 // 99MB
// https://developers.cloudflare.com/r2/reference/limits/#account-plan-limits
const MAX_SIZE = 5 * 1024 ** 3 // 5 GB

interface R2MultipartUpload {
  readonly key: string
  readonly uploadId: string
}
interface R2UploadedPart {
  readonly partNumber: number
  readonly etag: string
}

interface UploadedObject {
  readonly url: string
}

type ProgressFn = (payload: { sent: number, total: number, percentage: number }) => void

interface UploadParams {
  onProgressFn?: ProgressFn
}

const baseURL = 'https://individuallist.xyz/media/upload'

export async function upload(file: File, { onProgressFn }: UploadParams = {}) {
  if (file.size > MAX_SIZE) {
    throw new Error(`File size ${file.size} too large`)
  }
  if (file.size <= PART_SIZE) {
    return $fetch<UploadedObject>(`/${file.name}`, {
      baseURL,
      method: 'PUT',
      body: file,
    })
  }

  const parts = Array(Math.ceil(file.size / PART_SIZE)).fill(0).map((_, index) => {
    const start = index * PART_SIZE
    const end = start + PART_SIZE
    return {
      index,
      start: index * PART_SIZE,
      end: end > file.size ? file.size : end,
    }
  })
  const { uploadId } = await $fetch<R2MultipartUpload>(`/create/${file.name}`, { baseURL, method: 'POST' })

  let uploadedSize = 0
  const progressCache: Record<number, number> = {}
  const uploadedParts = await Promise.all(parts.map(({ index, start, end }) => axios<R2UploadedPart>({
    method: 'PUT',
    url: `${baseURL}/${file.name}/${uploadId}/${index + 1}`,
    data: file.slice(start, end),
    onUploadProgress: function ({ loaded, upload }) {
      progressCache[index] = loaded
      if (upload) {
        uploadedSize += progressCache[index] || 0
        delete progressCache[index]
      }
      const inProgress = Object.keys(progressCache).map(Number).reduce((memo, id) => (memo += progressCache[id]), 0)
      const sent = Math.min(uploadedSize + inProgress, file.size)
      if (onProgressFn) {
        onProgressFn({
          sent,
          total: file.size,
          percentage: Math.round((sent / file.size) * 100),
        })
      }
    },
  }).then(function (response) { return response.data })))

  return $fetch<UploadedObject>(`/finish/${file.name}/${uploadId}`, {
    baseURL,
    method: 'POST',
    body: { parts: uploadedParts },
  })
}
