import { AudioVisualizer } from '~/lib/audio/utils'

export default defineNuxtPlugin(() => {
  const isRecording = ref(false)
  const isPaused = ref(false)
  const recordingBlob = ref<Blob>()
  const recordingState = ref<RecordingState>('inactive')

  let mediaRecorder: MediaRecorder | undefined
  let timerInterval: number | undefined

  const _recordingTime = ref(0)

  const audioVisualizer = ref<AudioVisualizer>()

  function startTimer() {
    timerInterval = window.setInterval(() => {
      _recordingTime.value = _recordingTime.value + 1
    }, 1000)
  }

  function stopTimer() {
    if (timerInterval) {
      window.clearInterval(timerInterval)
    }
    timerInterval = undefined
  }

  function startRecording() {
    audioVisualizer.value = new AudioVisualizer()

    if (timerInterval) {
      return
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        isRecording.value = true

        const recorder = new MediaRecorder(stream)
        recorder.addEventListener('dataavailable', (event) => {
          recordingBlob.value = event.data
          recorder.stream.getTracks().forEach(t => t.stop())
          mediaRecorder = undefined
        })

        audioVisualizer.value?.start(stream)
        mediaRecorder = recorder
        recorder.start()

        startTimer()
        recordingState.value = 'recording'
      })
      .catch(err => console.error(err))
  }

  function stopRecording() {
    mediaRecorder?.stop()

    stopTimer()
    _recordingTime.value = 0
    isRecording.value = false
    isPaused.value = false
    recordingState.value = 'inactive'

    audioVisualizer.value?.stop()
  }

  function pauseRecording() {
    mediaRecorder?.pause()

    stopTimer()
    isPaused.value = true
    recordingState.value = 'paused'

    audioVisualizer.value?.pause()
  }

  function resumeRecording() {
    mediaRecorder?.resume()

    startTimer()
    isPaused.value = false
    recordingState.value = 'recording'

    audioVisualizer.value?.resume()
  }

  function togglePauseAndResume() {
    if (isPaused.value) {
      resumeRecording()
    }
    else {
      pauseRecording()
    }
  }

  // TODO nuxtApp.$pageLifecycle.addFrozenListener(stopRecording)

  return {
    provide: {
      audioRecorder: {
        startRecording,
        stopRecording,
        togglePauseAndResume,
        pauseRecording,
        resumeRecording,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime: computed(() => new Date(_recordingTime.value * 1000).toISOString().slice(11, 19)),
        recordingState,
        audioVisualizer,
      },
    },
  }
})
