import { upload } from './upload'
import type { mastodon } from '#types'
import type { ContentMedia, UploadContentMedia, VideoPosterFn, WaveformDataFn } from '~/types/content-media'

interface CreateContentUpload {
  statusId?: string
  conversationId?: string
  publishId: string
  inReplyToId?: string
  uploadMedia?: UploadContentMedia[]
  html?: string
  visibility?: mastodon.v1.StatusVisibility
}

type ProgressFileFn = (file: File, payload: { sent: number, total: number, percentage: number }) => void

export async function uploadContent({ statusId, conversationId, publishId, inReplyToId, html, uploadMedia, visibility }: CreateContentUpload, { onVideoPoster, onWaveformData, onProgress }: { onVideoPoster?: VideoPosterFn, onWaveformData?: WaveformDataFn, onProgress?: ProgressFileFn } = {}) {
  console.log('upload', { conversationId, publishId, html, media: uploadMedia?.length })
  let content = html
  if (uploadMedia?.length) {
    const items = await Promise.all(uploadMedia.map(media => processMedia(media, { onVideoPoster, onWaveformData, onProgress })))
    content = await updateHtml(content ?? '', items.filter((item): item is ContentMedia => Boolean(item)))
  }
  if (content) {
    const id = statusId ? `/${statusId}` : ''
    const headers = useRequestHeaders(['cookie'])
    if (conversationId) {
      await $fetch<mastodon.v1.Status>(`/api/conversations/${conversationId}/messages${id}`, {
        method: statusId ? 'PUT' : 'POST',
        headers,
        body: {
          publishId,
          content,
        },
      })
    }
    else {
      await $fetch<mastodon.v1.Status>(`/api/statuses${id}`, {
        method: statusId ? 'PUT' : 'POST',
        headers,
        body: {
          visibility,
          publish_id: publishId,
          in_reply_to_id: inReplyToId,
          status: content,
        },
      })
    }
  }
  return Promise.resolve(undefined)
}

async function processMedia({ file, ...media }: UploadContentMedia, { onVideoPoster, onWaveformData, onProgress }: { onVideoPoster?: VideoPosterFn, onWaveformData?: WaveformDataFn, onProgress?: ProgressFileFn }): Promise<ContentMedia | undefined> {
  const posterFiles: File[] = []
  const [contentMedia, { url }] = await Promise.all([
    toContentMedia(file, file.name, media, onWaveformData, (file) => {
      onVideoPoster?.(file)
      posterFiles.push(file)
    }),
    postFile(file, onProgress),
  ])
  if (contentMedia) {
    for (const file of posterFiles) {
      const { url } = await postFile(file, onProgress)
      if (contentMedia?.type === 'video') {
        contentMedia.posterUrl = url
      }
    }
    URL.revokeObjectURL(contentMedia.src)
    contentMedia.src = url
  }
  return contentMedia
}

function postFile(file: File, onProgress?: ProgressFileFn) {
  return upload(file, {
    onProgressFn: (payload) => {
      onProgress?.(file, payload)
    },
  })
}
