import { default as _91status_93LVxZ2Yz4KQMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/@[account]/index/[status].vue?macro=true";
import { default as composeF6RGPnt6m0Meta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/@[account]/index/compose.vue?macro=true";
import { default as indexTLc9TH9QQHMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/@[account]/index/index.vue?macro=true";
import { default as indexz2yT2bkuQTMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/@[account]/index.vue?macro=true";
import { default as indexW5MPgkHvIgMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/admin/index.vue?macro=true";
import { default as indexyeAFoEnbDfMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/index.vue?macro=true";
import { default as authorizeQRS9rOxYznMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/oauth/authorize.vue?macro=true";
import { default as searchyrNzDi2ZFVMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/search.vue?macro=true";
import { default as sent3Yl2GEswutMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/sent.vue?macro=true";
import { default as signin4YLoy8dZ2CMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/signin.vue?macro=true";
import { default as signupVQBFcfb9jDMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/signup.vue?macro=true";
import { default as indexiOsNRWjNTpMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/tags/[id]/index.vue?macro=true";
import { default as profilest4UEzp3aGSMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/tags/[id]/profiles.vue?macro=true";
import { default as _91id_93Y2Z37Gn8hBMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/tags/[id].vue?macro=true";
import { default as termscge3pOygvdMeta } from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/terms.vue?macro=true";
export default [
  {
    name: "account-index",
    path: "/@:account()",
    meta: indexz2yT2bkuQTMeta || {},
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/@[account]/index.vue"),
    children: [
  {
    name: "status",
    path: ":status()",
    meta: _91status_93LVxZ2Yz4KQMeta || {},
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/@[account]/index/[status].vue")
  },
  {
    name: "compose",
    path: "compose",
    meta: composeF6RGPnt6m0Meta || {},
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/@[account]/index/compose.vue")
  },
  {
    name: "@account-index",
    path: "",
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/@[account]/index/index.vue")
  }
]
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexW5MPgkHvIgMeta || {},
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/admin/index.vue")
  },
  {
    name: "actors-index",
    path: "/",
    meta: indexyeAFoEnbDfMeta || {},
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/index.vue")
  },
  {
    name: "oauth-authorize",
    path: "/oauth/authorize",
    meta: authorizeQRS9rOxYznMeta || {},
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/oauth/authorize.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/search.vue")
  },
  {
    name: "sent",
    path: "/sent",
    meta: sent3Yl2GEswutMeta || {},
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/sent.vue")
  },
  {
    name: "signin",
    path: "/signin",
    meta: signin4YLoy8dZ2CMeta || {},
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/signin.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupVQBFcfb9jDMeta || {},
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/signup.vue")
  },
  {
    name: _91id_93Y2Z37Gn8hBMeta?.name,
    path: "/tags/:id()",
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/tags/[id].vue"),
    children: [
  {
    name: "tags-id",
    path: "",
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/tags/[id]/index.vue")
  },
  {
    name: "tags-id-profiles",
    path: "profiles",
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/tags/[id]/profiles.vue")
  }
]
  },
  {
    name: "terms",
    path: "/terms",
    meta: termscge3pOygvdMeta || {},
    component: () => import("/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/pages/terms.vue")
  }
]