import { waveformData } from '~/lib/audio/utils/waveform-data'
import { fileOpen } from '~/lib/file-open'
import type { ContentMedia, UploadContentMedia, VideoPosterFn, WaveformDataFn } from '~/types'

const mimeExtension = new Map<string, [string, ContentMedia['type']]>([
  ['image/jpeg', ['jpg', 'image']],
  ['image/png', ['png', 'image']],
  ['image/gif', ['gif', 'image']],
  ['image/webp', ['webp', 'image']],
  ['image/avif', ['avif', 'image']],
  ['video/webm', ['webm', 'video']],
  ['video/mp4', ['mp4', 'video']],
  ['video/ogg', ['ogv', 'video']],
  ['audio/mp3', ['mp3', 'video']],
  ['audio/mpeg', ['mpga', 'audio']],
  ['audio/webm', ['weba', 'audio']],
  ['audio/wave', ['wav', 'audio']],
  ['audio/wav', ['wav', 'audio']],
  ['audio/mp4', ['mp4', 'audio']],
  ['audio/ogg', ['ogg', 'audio']],
])

export async function pickFiles() {
  const res = await fileOpen({ multiple: true })
  const files: UploadContentMedia[] = []
  for (const file of res) {
    const contentMedia = asContentMedia(file, file.name)
    if (contentMedia?.id) {
      files.push({
        ...contentMedia,
        file: new File([file], contentMedia.id, { lastModified: new Date().getTime(), type: file.type }),
      })
    }
    if (contentMedia?.type === 'video') {
      contentMedia.posterTime = 0.25
    }
  }
  return files
}

export async function pickContentMedia() {
  const res = await fileOpen({ multiple: true, mimeTypes: ['image/*', 'video/*', 'audio/*'] })
  const media: UploadContentMedia[] = []
  for (const file of res) {
    const contentMedia = await toContentMedia(file, file.name)
    if (contentMedia?.id) {
      media.push({
        ...contentMedia,
        file: new File([file], contentMedia.id, { lastModified: new Date().getTime(), type: file.type }),
      })
    }
  }
  return media
}

export function asContentMedia(blob: Blob, fileName?: string, contentMedia?: ContentMedia) {
  const mime = getType(blob.type, fileName)
  if (mime) {
    const [ext, type] = mime
    const media: ContentMedia = {
      id: `${crypto.randomUUID()}.${ext}`,
      src: URL.createObjectURL(blob),
      type,
      ...contentMedia,
    }
    if (media.type === 'file') {
      media.fileType = blob.type
    }
    return media
  }
}

export async function toContentMedia(blob: Blob, fileName?: string, oldMedia?: ContentMedia, onWaveformData?: WaveformDataFn, onVideoPoster?: VideoPosterFn) {
  const media = asContentMedia(blob, fileName, oldMedia)
  if (media?.type === 'audio' && (!media?.wave || !media?.duration)) {
    try {
      const { wave, duration } = await waveformData(blob)
      if (onWaveformData && media.id) {
        onWaveformData(media.id, { wave, duration })
      }
      media.wave = wave
      media.duration = duration
    }
    catch (e) {
      media.error = e
    }
  }
  if (media?.type === 'image' && (!media?.aspect)) {
    try {
      media.aspect = await imageAspectRatio(media.src)
    }
    catch (e) {
      media.error = e
    }
    return media
  }
  if (media?.type === 'video' && (!media?.duration || !media?.aspect || !media?.posterUrl)) {
    try {
      const { duration, aspect, poster } = await videoMeta(media.src, media.posterTime)
      media.duration = duration
      media.aspect = aspect
      if (onVideoPoster && poster) {
        onVideoPoster(poster)
      }
    }
    catch (e) {
      media.error = e
    }
  }
  return media
}

function getType(mimeType: string, fileName?: string): [string, ContentMedia['type']] | undefined {
  const [name] = mimeType.split(';')
  let mime = mimeExtension.get(name)
  if (!mime && fileName) {
    let ext = fileName.split('.').pop()
    if (ext) {
      ext = ext === 'jpeg' ? 'jpg' : ext
      for (const [, item] of mimeExtension) {
        const [extension] = item
        if (extension === ext) {
          mime = item
        }
      }
    }
  }
  return mime
}

function imageAspectRatio(url: string) {
  return new Promise<number>((resolve, reject) => {
    const img = new Image()
    img.onerror = (err) => {
      reject(err)
    }
    img.onload = () => {
      resolve(img.naturalWidth / img.naturalHeight)
    }
    img.src = url
  })
}

function videoMeta(url: string, posterTime?: number) {
  return new Promise<{ duration: number, aspect: number, poster?: File }>((resolve, reject) => {
    const video = document.createElement('video')
    video.onerror = (err) => {
      reject(err)
    }
    video.onloadedmetadata = () => {
      const { duration, videoWidth, videoHeight } = video
      if (posterTime === undefined) {
        resolve({
          duration,
          aspect: videoWidth / videoHeight,
        })
      }
      else {
        video.onseeked = () => {
          const { videoWidth, videoHeight } = video
          const canvas = document.createElement('canvas')
          canvas.width = videoWidth
          canvas.height = videoHeight

          canvas.getContext('2d')?.drawImage(video, 0, 0, videoWidth, videoHeight)
          canvas.toBlob((blob) => {
            resolve({
              duration,
              aspect: videoWidth / videoHeight,
              poster: blob ? new File([blob], `${crypto.randomUUID()}.jpg`, { lastModified: new Date().getTime(), type: blob.type }) : undefined,
            })
          }, 'image/jpg', 95)
        }
        video.currentTime = posterTime * duration
      }
    }
    video.src = url
  })
}
