import { getAudioContext } from './audio-context'
import { useWaveformDrawing } from '~/composables/audio/waveform-draw'

function visualizeFrequencyBars(analyser: AnalyserNode, canvas: HTMLCanvasElement, abort: AbortController) {
  const { draw } = useWaveformDrawing(ref(canvas))
  const bufferLength = analyser.frequencyBinCount
  const dataArray = new Uint8Array(bufferLength)

  const normalize = (n: number) => n / 255

  const drawWave = () => {
    if (!abort.signal.aborted) {
      requestAnimationFrame(drawWave)
    }
    analyser.getByteFrequencyData(dataArray)
    draw(dataArray, normalize)
  }
  drawWave()
}

function visualizeSineWave(analyser: AnalyserNode, canvas: HTMLCanvasElement, abort: AbortController) {
  const { draw } = useWaveformDrawing(ref(canvas))
  const bufferLength = analyser.fftSize
  const dataArray = new Uint8Array(bufferLength)
  analyser.getByteTimeDomainData(dataArray)
  const normalize = (n: number) => ((n < 128 ? 255 - n : n) - 128) / 128
  function drawWave(): void {
    if (!abort.signal.aborted) {
      requestAnimationFrame(drawWave)
    }
    analyser.getByteTimeDomainData(dataArray)
    draw(dataArray, normalize)
  }
  drawWave()
}

export class AudioVisualizer {
  private audioContext?: AudioContext
  private readonly abort = new AbortController()
  canvas?: HTMLCanvasElement

  start(stream: MediaStream): void {
    this.audioContext = getAudioContext()
    const analyser = this.audioContext.createAnalyser()
    this.audioContext.createMediaStreamSource(stream).connect(analyser)
    if (this.canvas) {
      visualizeFrequencyBars(analyser, this.canvas, this.abort)
    }
  }

  pause(): void {
    this.audioContext?.suspend()
  }

  resume(): void {
    this.audioContext?.resume()
  }

  stop(): void {
    this.abort.abort()
  }
}
