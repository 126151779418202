import type { ContentMedia } from '~/types'

export function tagToMediaType (tag: string): ContentMedia['type'] | undefined {
  switch (tag) {
    case 'img': return 'image'
    case 'audio':
    case 'video':
      return tag
    case 'a': return 'file'
    default:
      return undefined
  }
}

export function mediaTypeToTag(type: string) {
  type = type?.split('/')[0] ?? ''
  switch (type) {
    case 'image': return 'img'
    case 'audio':
    case 'video':
      return type
    default:
      return 'a'
  }
}
