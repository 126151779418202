import { getAudioContext } from '~/lib/audio/utils/audio-context'
import type { WaveformData } from '~/types/content-media'

const numSamples = 300

export async function waveformData(blob?: Blob): Promise<WaveformData> {
  if (!blob) {
    return { wave: [], duration: NaN }
  }
  const audioContext = getAudioContext()
  const arrayBuffer = await blob.arrayBuffer()
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)

  const rawData = audioBuffer.getChannelData(0)
  const blockSize = Math.floor(rawData.length / numSamples)
  const samples = []
  for (let i = 0; i < numSamples; i++) {
    const blockStart = blockSize * i
    let sum = 0
    for (let j = 0; j < blockSize; j++) {
      const v = Math.abs(rawData[blockStart + j])
      sum = sum + v
    }
    samples.push(sum)
  }

  const max = Math.max(...samples)
  return {
    wave: samples.map(v => v / max),
    duration: audioBuffer.duration
  }
}
