import revive_payload_client_YTGlmQWWT5 from "/home/runner/work/individuallist.xyz/individuallist.xyz/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/runner/work/individuallist.xyz/individuallist.xyz/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/runner/work/individuallist.xyz/individuallist.xyz/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/runner/work/individuallist.xyz/individuallist.xyz/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/home/runner/work/individuallist.xyz/individuallist.xyz/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/runner/work/individuallist.xyz/individuallist.xyz/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/home/runner/work/individuallist.xyz/individuallist.xyz/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/runner/work/individuallist.xyz/individuallist.xyz/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _1_page_lifecycle_client_kwpJV3nqfU from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/plugins/1.page-lifecycle.client.ts";
import audio_recorder_client_oEcIEGIpvG from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/plugins/audio-recorder.client.ts";
import auto_animate_ur7RJi6COd from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/plugins/auto-animate.ts";
import dayjs_NBCB9zH19f from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/plugins/dayjs.ts";
import fluent_vue_dprCk5JGUA from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/plugins/fluent-vue.ts";
import hydration_client_7uir9Lpz2B from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/plugins/hydration.client.ts";
import media_upload_client_UUiFLqpO67 from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/plugins/media-upload.client.ts";
import portal_WBzLfSYWSd from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/plugins/portal.ts";
import ws_client_o24Ix7PRaH from "/home/runner/work/individuallist.xyz/individuallist.xyz/packages/site/src/plugins/ws.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  _1_page_lifecycle_client_kwpJV3nqfU,
  audio_recorder_client_oEcIEGIpvG,
  auto_animate_ur7RJi6COd,
  dayjs_NBCB9zH19f,
  fluent_vue_dprCk5JGUA,
  hydration_client_7uir9Lpz2B,
  media_upload_client_UUiFLqpO67,
  portal_WBzLfSYWSd,
  ws_client_o24Ix7PRaH
]