import type { Node } from 'ultrahtml'
import { ELEMENT_NODE, parse, transform, walkSync } from 'ultrahtml'

import { mediaTypeToTag, tagToMediaType } from '~/lib/tag-media-type'
import type { ContentMedia, ContentMediaAudio, ContentMediaFile, ContentMediaImage, ContentMediaVideo, UploadContentMedia } from '~/types'

type Attr = Record<string, string>
type ViewType = 'grid' | 'slideshow'

const precision = 1000
const delimiter = ','

export function createHtml(media: ContentMedia[], publishId: string) {
  const items = media.map(media => ({ ...media, publishId })).map(mediaHtml)
  if (items.length > 1) {
    return mediaViewerHtml(items, publishId)
  }
  else if (media.length === 1) {
    return items[0]
  }
}

export function updateHtml(html: string, newItems: ContentMedia[]) {
  const handleMedia = (items: ContentMedia[]) => {
    return (doc: Node): Node => {
      walkSync(doc, (node: Node) => {
        if (node.type === ELEMENT_NODE && nodeIsMedia(node)) {
          const item = items.find(media => media.id === node.attributes['data-id'])
          if (item) {
            node.attributes = { ...node.attributes, ...mediaAttrs(item) }
          }
        }
      })
      return doc
    }
  }
  return transform(html, [handleMedia(newItems)])
}

export function getUploadMediaFromHtml(html: string, files: File[]) {
  const media: UploadContentMedia[] = []
  walkSync(parse(html), (node: Node) => {
    if (node.type === ELEMENT_NODE && nodeIsMedia(node)) {
      const file = files.find(file => file.name === node.attributes['data-id'])
      const type = tagToMediaType(node.name)
      if (file && type) {
        media.push({ ...attributesToMedia(type, node.attributes), file })
      }
    }
  })
  return media
}

export function nodeIsMediaViewer(node: Node): boolean {
  return node.name === 'ul' && node.attributes['data-media-viewer']
}
export function nodeIsMedia(node: Node): boolean {
  return ['img', 'audio', 'video'].includes(node.name) || (node.name === 'a' && node.attributes['data-file'])
}

export function mediaViewerHtml(items: string[], publishId?: string, viewType?: ViewType) {
  const attrs = mediaViewerAttrs(viewType, publishId)
  return `<ul ${attrsToString(attrs)}>${items.map(item => `<li>${item}</li>`).join('')}</ul>`
}

function mediaHtml(media: ContentMedia) {
  const attrs = mediaAttrs(media)
  const tag = mediaTypeToTag(media.type)
  if (tag === 'a') {
    const txt = attrs.src.split('/').pop()
    return `<a ${attrsToString(attrs)}>${txt}</a>`
  }
  return `<${tag} ${attrsToString(attrs)} />`
}

export function mediaViewerAttrs(type?: ViewType, publishId?: string) {
  return cleanAttributes({
    ...(type ? { 'data-type': type } : {}),
    ...(publishId ? { 'data-publish-id': publishId } : {}),
    'data-media-viewer': 'true',
  })
}

export function attributesToMediaViewer(attrs: Attr) {
  const { 'data-type': type, 'data-publish-id': publishId } = attrs
  return { type, publishId }
}

export function mediaAttrs(media: ContentMedia) {
  const attrs: Attr = {}
  switch (media.type) {
    case 'image': {
      const { src, aspect } = media satisfies ContentMediaImage
      attrs.src = src
      if (aspect !== undefined) {
        attrs['data-aspect'] = `${aspect}`
      }
      break
    }
    case 'video': {
      const { src, aspect, duration, posterUrl, posterTime } = media satisfies ContentMediaVideo
      attrs.src = src
      if (aspect !== undefined) {
        attrs['data-aspect'] = `${aspect}`
      }
      if (duration !== undefined) {
        attrs['data-duration'] = `${duration}`
      }
      if (posterUrl) {
        attrs['data-poster-url'] = posterUrl
      }
      if (posterTime !== undefined) {
        attrs['data-poster-time'] = `${posterTime}`
      }
      break
    }
    case 'audio': {
      const { src, wave, duration } = media satisfies ContentMediaAudio
      attrs.src = src
      if (wave !== undefined) {
        attrs['data-wave'] = wave.map(v => Math.round(v * precision)).join(delimiter)
      }
      if (duration !== undefined) {
        attrs['data-duration'] = `${duration}`
      }
      break
    }
    case 'file': {
      const { src, fileType, posterUrl } = media satisfies ContentMediaFile
      attrs.href = src
      attrs['data-file'] = 'true'
      attrs.download = 'true'
      if (fileType) {
        attrs['data-type'] = fileType
      }
      if (posterUrl) {
        attrs['data-poster-url'] = posterUrl
      }
      break
    }
  }
  if (media.id) {
    attrs['data-id'] = media.id
  }
  if (media.publishId) {
    attrs['data-publish-id'] = media.publishId
  }
  if (media.error) {
    attrs['data-error'] = media.error
  }
  return attrs
}

export function attributesToMedia(type: ContentMedia['type'], attrs: Attr) {
  return {
    type,
    ...Object.entries(attrs).reduce((res, [key, value]) => {
      switch (key) {
        case 'src': return { ...res, src: value }
        case 'data-id': return { ...res, id: value }
        case 'data-publish-id': return { ...res, publishId: value }
        case 'data-error': return { ...res, error: value }
        case 'data-wave': return { ...res, wave: waveParse(value) }
        case 'data-duration': return { ...res, duration: parseFloat(value) }
        case 'data-aspect': return { ...res, aspect: parseFloat(value) }
        case 'data-poster-url': return { ...res, posterUrl: value }
        case 'data-poster-time': return { ...res, posterTime: parseFloat(value) }
        case 'data-type': return { ...res, fileType: value }
        default: return res
      }
    }, {}),
  } as ContentMedia
}

export function nodeToMediaViewerProps(node: Node) {
  const { 'data-type': type, 'data-publish-id': publishId } = node.attributes as Record<string, string>
  const mediaItems = node.children.map(({ children: [child] }: Node) => {
    return nodeIsMedia(child) ? nodeToMedia(child) : undefined
  }).filter(Boolean) as ContentMedia[]
  return { type, mediaItems, publishId }
}

export function nodeToMedia(node: Node): ContentMedia | undefined {
  const type = tagToMediaType(node.name)
  if (!type) {
    return
  }
  return attributesToMedia(type, node.attributes)
}

export function elementToMedia(el: Element): ContentMedia | undefined {
  const type = tagToMediaType(el?.tagName?.toLocaleLowerCase())
  if (!type) {
    return
  }
  const attributes = Array.from(el.attributes).reduce<Attr>((a, { name, value }) => ({ ...a, [name]: value }), {})
  return attributesToMedia(type, attributes)
}

function attrsToString(attrs: Attr) {
  return Object.entries(attrs).map(([key, val]) => `${key}="${val}"`).join(' ')
}

function cleanAttributes(attrs: Attr) {
  return Object.fromEntries(Object.entries(attrs).filter(([, val]) => typeof val !== 'undefined'))
}

function waveParse(waveString: string) {
  return waveString.split(delimiter).map(v => parseFloat(v) / precision)
}
