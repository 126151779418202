import type { mastodon } from '#types'

interface EmailAuthenticationData {
  email: string
  ticket: string
}

interface EmailConfirmationData {
  email: string
  url: string
}

interface OauthAuthorizeData {
  redirectUri: string
  responseType: string
  clientId: string
  state: string
}

interface OauthAuthorizeResponse {
  redirectUri: string
  firstLogin: boolean
}

export interface AccountSession {
  account?: mastodon.v1.Account
}

export const useAuth = () => {
  const sessionState = useState<AccountSession>('nuxt-session', () => ({}))

  /**
   * Send programmatically an email to a account in order to confirm his account
   */
  const sendSignInLinkToEmail = async (body: EmailConfirmationData): Promise<void> => {
    await $fetch('/api/auth/mail/send', { method: 'POST', body })
  }

  /**
   * Authenticate account & retrieve his JWT
   */
  const signInWithEmailLink = async (body: EmailAuthenticationData): Promise<void> => {
    const account = await $fetch<mastodon.v1.Account>('/api/auth/mail/verify', { method: 'POST', body })
    sessionState.value = { account }
  }

  /**
   * Logout by removing authentication token
   */
  const logout = async (): Promise<void> => {
    await $fetch('/api/auth/logout', { method: 'POST' })
    sessionState.value = {}
  }

  const oauthAuthorize = async (body: OauthAuthorizeData): Promise<OauthAuthorizeResponse> => {
    return await $fetch<OauthAuthorizeResponse>('/api/auth/oauth/authorize', { method: 'POST', body })
  }

  function checkLogin() {
    if (!sessionState.value.account) {
      openSigninDialog()
      return false
    }
    return true
  }

  return {
    loggedIn: computed(() => Boolean(sessionState.value.account)),
    account: computed({
      get: () => sessionState.value.account,
      set: (val) => {
        sessionState.value.account = val
      },
    }),
    signInWithEmailLink,
    sendSignInLinkToEmail,
    logout,
    checkLogin,
    oauthAuthorize,
  }
}
