import { defaultLocale, locales } from '#backend/locale'
import { FluentBundle, FluentResource } from '@fluent/bundle'
import { useFluent } from 'fluent-vue'
import * as en from '../locales/en.json'
import * as ru from '../locales/ru-RU.json'
import * as de from '../locales/de-DE.json'

function toFluentSource(locale: 'en' | 'ru' | 'de', source: string, path = '') {
  let items = source.split('|')
  let value = source
  if (items.length > 1) {
    items = items
      .map(item => item.trim())
      .map(item => item.replace('{0}', '{$count}'))
      .map(item => item.replace('{n}', '{$count}'))
      .map(item => item.replace('{v}', '{$count}'))
    switch (locale) {
      case 'en': {
        const [zero, one, other] = items
        value = `{NUMBER($count, type: "ordinal") -> [zero] ${zero}\n[one] ${one}\n*[other] ${other}\n}`
        break
      }
      case 'ru': {
        const [one, few, many, other] = items
        value = `{NUMBER($count, type: "ordinal") -> [one] ${one}\n[few] ${few}\n[many] ${many}\n*[other] ${other}\n}`
        break
      }
      case 'de': {
        const [zero, one, other] = items
        value = `{NUMBER($count, type: "ordinal") -> [zero] ${zero}\n[one] ${one}\n*[other] ${other}\n}`
        break
      }
      default:
        throw new TypeError(`Unknown locale ${locale}`)
    }
  }
  return `${path} = ${value}`
}

function traverse(locale: 'en' | 'ru' | 'de', bundle: FluentBundle, obj: unknown, path = '') {
  if (obj !== null && typeof obj === 'object') {
    for (const [key, value] of Object.entries(obj)) {
      const separator = path ? '-' : ''
      traverse(locale, bundle, value, `${path}${separator}${key}`)
    }
  } else if (typeof obj === 'string') {
    bundle.addResource(new FluentResource(toFluentSource(locale, obj, path)))
  } else {
    throw new TypeError(`Unknown type ${typeof obj}`)
  }
}

function load(locale: string) {
  const bundle = new FluentBundle(locale)
  switch (locale) {
    case 'ru':
      traverse(locale, bundle, ru)
      break
    case 'en':
      traverse(locale, bundle, en)
      break
    case 'de':
      traverse(locale, bundle, de)
      break
  }
  return bundle
}

export const useCurrentLocale = () => {
  const state = useState<string>('nuxt-locale', () => (defaultLocale))

  return {
    setLocale: (value?: string) => {
      state.value = value ?? defaultLocale
    },
    bundles: () => [
      load(state.value),
      ...(state.value !== 'en' ? [load('en')] : [])
    ],
    locale: state
  }
}

export const useLocale = () => {
  const { locale: currentLocale, setLocale } = useCurrentLocale()
  const fluent = useFluent()

  return {
    locales: Object.entries(locales).map<{ code: string, name: string }>(([key, value]) => ({ ...value, code: key })),
    locale: computed({
      get: () => currentLocale.value,
      set: async (locale: string) => {
        setLocale(locale)
        fluent.bundles.value = [load(locale)]
        await $fetch('/api/locale', { method: 'POST', body: { locale } }).catch(noop)
      }
    })
  }
}
