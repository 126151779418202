export async function fileOpen({ mimeTypes = [], multiple = false }: { mimeTypes?: string[], multiple?: boolean }): Promise<File[]> {
  return new Promise((resolve) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = multiple
    input.accept = mimeTypes.join(',')

    // Append to the DOM, else Safari on iOS won't fire the `change` event reliably.
    input.style.display = 'none'
    // document.body.append(input)

    const cancelDetector = () => {
      window.removeEventListener('focus', cancelDetector)
      input.remove()
    }

    input.addEventListener('click', () => {
      window.addEventListener('focus', cancelDetector)
    })
    input.addEventListener('change', () => {
      window.removeEventListener('focus', cancelDetector)
      input.remove()
      if (input.files === null) {

      }
      const files: File[] = input.files === null ? [] : Array.from(input.files)
      resolve(files)
    })

    if ('showPicker' in HTMLInputElement.prototype) {
      input.showPicker()
    } else {
      input.click()
    }
  })
}
