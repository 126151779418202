declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext
  }
}

let audioCtx: AudioContext

export function getAudioContext(): AudioContext {
  if (!audioCtx) {
    audioCtx = new (window.AudioContext || window.webkitAudioContext)()
  }
  return audioCtx
}
